import { container } from "tsyringe";
import { ProductDataMapper } from "@/data/persistences/mappers/ProductDataMapper";
import { ProductPresenter } from "@/app/ui/presenters/ProductPresenter";
import { ProductApiRepository } from "@/app/infrastructures/repositories/api/ProductApiRepository";
import ApiService from "@/app/infrastructures/services/ApiService";
import { ProductEndpoint } from "@/app/infrastructures/endpoints/horde/ProductEndpoint";

export class ProductComponent {
  public static init() {
    container.register<ProductApiRepository>(ProductApiRepository, {
      useFactory: d => {
        return new ProductApiRepository(
          d.resolve(ApiService),
          d.resolve(ProductDataMapper),
          d.resolve(ProductEndpoint)
        );
      }
    });
    container.register<ProductDataMapper>(ProductDataMapper, {
      useClass: ProductDataMapper
    });
    container.register<ProductPresenter>(ProductPresenter, {
      useFactory: d => {
        return new ProductPresenter(d.resolve(ProductApiRepository));
      }
    });
  }
}
