
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { EmbargoController } from "@/app/ui/controllers/EmbargoController";
import {
  convertArrayToString,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";

@Options({
  components: {
    Input,
    InputAdornment
  }
})
export default class Add extends Vue {
  goBack() {
    this.$router.back();
  }

  id() {
    return this.$route.params.id;
  }

  // Loading
  get isLoading() {
    return EmbargoController.isLoading;
  }

  form = {
    name: "",
    maxWeight: 0
  };

  item = {
    status: "Active"
  };

  // Modal Success
  get openSuccess() {
    return EmbargoController.openSuccess;
  }
  onCloseSuccess() {
    EmbargoController.setOpenSuccess(false);
    this.$router.push(`/network/embargo`);
  }

  // Save data
  get openSave() {
    return EmbargoController.openModal;
  }
  onOpenSave() {
    return EmbargoController.setOpenModal(true);
  }
  onCloseSave() {
    EmbargoController.setOpenModal(false);
  }

  _onCreate() {
    EmbargoController._onAdd({
      embargoName: this.form.name,
      originId: this.convertToString(this.originCityExclude, "code"),
      destinationId: this.convertToString(this.destinationCityExclude, "code"),
      serviceType: this.convertToString(this.typeExclude, "value"),
      productType: this.convertToString(this.productExclude, "name"),
      commodityType: this.convertToString(
        this.commodityExclude,
        "commodity_id"
      ),
      maxWeight: Number(this.form.maxWeight),
      startDateTime: this.convertDateTime(this.periodeStart, this.periodeStartTime),
      endDateTime: this.convertDateTime(this.periodeEnd, this.periodeEndTime)
    });
  }

  // disable
  get isDisabled() {
    return (
      !this.form.name ||
      this.originCityExclude.length === 0 ||
      this.destinationCityExclude.length === 0 ||
      this.productExclude.length === 0 ||
      this.commodityExclude.length === 0 ||
      !this.form.maxWeight ||
      this.invalidStartDate ||
      this.invalidStartTime ||
      this.invalidEndDate
    );
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return false;
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  mounted() {
    this.fetchOriginCity("");
    this.fetchDestinationCity("");
    this.fetchProduct("");
    this.fetchCommodity("");
    EmbargoController.setResetPeriodeStart();
    EmbargoController.setResetPeriodeEnd();
  }

  // Origin City
  fetchOriginCity(value: string) {
    if (value.length > 2 || !value) {
      EmbargoController.getCityOriginList({
        search: value,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }
  get originCityListData() {
    const tmp: Array<any> = [{ name: "ALL", code: "ALL" }];
    const origin = EmbargoController.originCityOptionsData;
    const isExist = this.destinationCityExclude.find(
      e => e.name === tmp[0].name
    );

    if (!isExist) {
      return [...tmp, ...origin];
    }
    return origin;
  }
  originCityExclude: Array<any> = [];
  get isLoadingOriginCity() {
    return EmbargoController.isLoadingOriginCity;
  }

  // Destination City
  fetchDestinationCity(value: string) {
    if (value.length > 2 || !value) {
      EmbargoController.getCityDestinationList({
        search: value,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }
  get destinationCityListData() {
    const tmp: Array<any> = [{ name: "ALL", code: "ALL" }];
    const destination = EmbargoController.destinationCityOptionsData;
    const isExist = this.originCityExclude.find(e => e.name === tmp[0].name);

    if (!isExist) {
      return [...tmp, ...destination];
    }
    return destination;
  }
  destinationCityExclude: Array<any> = [];
  get isLoadingDestinationCity() {
    return EmbargoController.isLoadingDestinationCity;
  }

  // Type
  typeListData: Array<any> = [
    { name: "PACKAGE", value: "PACKAGE" },
    { name: "DOCUMENT", value: "DOCUMENT" }
  ];
  typeExclude = [];
  get isLoadingType() {
    return false;
  }

  // Product
  fetchProduct(value: string) {
    EmbargoController.getProductList({
      search: value,
      status: "",
      page: 1,
      limit: 10,
      productCode: ""
    });
  }
  get productListData() {
    return EmbargoController.productOptionsData;
  }
  productExclude = [];
  get isLoadingProduct() {
    return EmbargoController.isLoadingProduct;
  }

  // Commodity
  fetchCommodity(value: string) {
    EmbargoController.getCommodityList({
      search: value,
      status: "",
      page: 1,
      limit: 10
    });
  }
  get commodityListData() {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const tmp: Array<any> = [{ commodity_id: "ALL", commodity_name: "ALL" }];
    return [...tmp, ...EmbargoController.commodityOptionsData];
  }
  commodityExclude = [];
  get isLoadingCommodity() {
    return EmbargoController.isLoadingCommodity;
  }

  // Date

  get minDatePeriodeStart() {
    const date = new Date();
    return new Date(date.setDate(date.getDate()));
  }
  get minDatePeriodeEnd() {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    );
  }
  get periodeStart(): any {
    return EmbargoController.convertPeriodeStart;
  }
  get periodeEnd(): any {
    return EmbargoController.convertPeriodeEnd;
  }
  get periodeStartTime(): any {
    return EmbargoController.periodeStartTime;
  }
  get periodeEndTime(): any {
    return EmbargoController.periodeEndTime;
  }
  inputDateStart(param: Date) {
    EmbargoController.setPeriodeStart(param);
  }

  inputDateEnd(param: Date) {
    EmbargoController.setPeriodeEnd(param);
  }

  inputDateStartTime(param: Date) {
    EmbargoController.setPeriodeStartTime(param);
  }

  inputDateEndTime(param: Date) {
    EmbargoController.setPeriodeEndTime(param);
  }

  get invalidStartTime() {
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(this.periodeStart))
    ) {
      return !(
        this.periodeStartTime.getHours() >= new Date().getHours() &&
        this.periodeStartTime.getMinutes() >= new Date().getMinutes() + 1
      );
    }
    return false;
  }

  get invalidStartDate() {
    return (
      new Date(formatDateWithoutTime(new Date(this.periodeStart))).getTime() >=
      new Date(formatDateWithoutTime(new Date(this.periodeEnd))).getTime()
    );
  }

  get invalidEndDate() {
    return (
      formatDateWithoutTime(new Date(this.periodeStart)) ===
        formatDateWithoutTime(new Date(this.periodeEnd)) ||
      new Date(this.periodeStart).getTime() >
        new Date(this.periodeEnd).getTime()
    );
  }

  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  convertDate(date: Date, time: string) {
    return new Date(
      `${date.getMonth() +
        1}-${date.getDate()}-${date.getFullYear()} ${time} UTC`
    ).toISOString();
  }

  // Only number
  filterInput(e: any) {
    e.target.value = e.target.value.replace(/\D+/g, "");
  }

  convertDateTime(date: Date, time: Date) {
    const resultDate = new Date(date).toLocaleDateString("fr-CA");

    const timeString = time?.toLocaleTimeString([], {
      hour12: false
    });
    return `${resultDate}T${timeString}.000+07:00`;
  }

  onChangeWeight(obj: any) {
    this.form.maxWeight = obj.weight;
  }
}
