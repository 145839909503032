import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import { ApiRequestList, QueryParamsEntities } from "@/domain/entities/MainApp";

export class ProductEndpoint {
  getListProduct(
    search: string,
    status: string,
    page: number,
    limit: number,
    productCode: string
  ): string {
    return `/horde/v1/product?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}&product_code=${productCode}`;
  }
  getDetailProduct(id: number): string {
    return `/horde/v1/product/${id}`;
  }
  editProduct(id: number): string {
    return `/horde/v1/product/${id}`;
  }
  addProduct(): string {
    return `/horde/v1/product`;
  }
  productApi(params: any) {
    const query = new QueryParamsEntities(params);
    return `/horde/v1/product?${query.queryString}`;
  }
  productConfigurationRuleEndpoint(
    params: ProductConfigurationRuleRequestParams
  ) {
    return `/horde/v1/configurable-rule?${params.toQueryString}`;
  }
  getSenderList(params: ApiRequestList) {
    return `/horde/v1/product?${params.toQueryString()}`;
  }
}
