import { Pagination } from "./Pagination";

export class ProductEntities {
  pagination: Pagination;
  data: ProductData[];
  constructor(pagination: Pagination, data: ProductData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ProductData {
  id = 0;
  name = "";
  eCargoProduct = "";
  description = "";
  status = "";
  createdBy = "";
  updatedBy = "";
  createdAt = "";
  updateAt = "";

  constructor(fields?: Partial<ProductData>) {
    Object.assign(this, fields);
  }
}

export class ProductConfigurationRule {
  product = "";
  isCampaign = false;
  parameter = 0.5;
  metaRule: any = "";
  constructor(fields?: Partial<ProductConfigurationRule>) {
    Object.assign(this, fields);
  }
}
export class ProductConfigurationRuleList {
  data: ProductConfigurationRule[] = [];
  constructor(fields?: Partial<ProductConfigurationRuleList>) {
    Object.assign(this, fields);
  }
  findProductConfigRule(
    productName: string,
    idBookedFor: any,
    retail: boolean
  ): ProductConfigurationRule {
    if (!productName) return new ProductConfigurationRule();
    const retailReverse = !retail;
    let resultCampaign, resultDefault;
    this.data.forEach((config: ProductConfigurationRule) => {
      if (
        config.product === productName &&
        config.isCampaign &&
        (!config.metaRule?.client.includes(idBookedFor) ||
          config.metaRule?.pos === retailReverse)
      ) {
        resultCampaign = config;
      }
      if (
        config.product === productName &&
        !config.isCampaign &&
        (!config.metaRule?.client.includes(idBookedFor) ||
          config.metaRule?.pos === retailReverse)
      ) {
        resultDefault = config;
      }
    });
    if (resultCampaign) return resultCampaign;
    if (resultDefault) return resultDefault;
    return new ProductConfigurationRule({
      product: productName,
      isCampaign: false,
      parameter: 0.31
    });
  }
  findProductConfigRuleDefault(productName: string): ProductConfigurationRule {
    if (!productName) return new ProductConfigurationRule();
    let resultDefault;
    this.data.forEach((config: ProductConfigurationRule) => {
      if (config.product === productName && !config.isCampaign) {
        resultDefault = config;
      }
    });
    if (resultDefault) return resultDefault;
    return new ProductConfigurationRule({
      product: productName,
      isCampaign: false,
      parameter: 0.31
    });
  }
}

export class ProductSender {
  productTypeId = 0;
  productTypeCode = "";
  productTypeDescription = "";
  productTypeStatus = "";
  productTypePriority = 0;
  productTypeCargoProductTypeCode = "";
  createdBy = 0;
  updatedBy = 0;
  createdAt = "";
  updatedAt = "";

  constructor(fields?: Partial<ProductSender>) {
    Object.assign(this, fields);
  }
}
