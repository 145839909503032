
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import { EmbargoController } from "@/app/ui/controllers/EmbargoController";
import debounce from "lodash/debounce";
import { ROUTE_EMBARGO } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isCreateAble() {
      return checkRolePermission(ROUTE_EMBARGO.CREATE) && !this.isError;
    },
    isDetailAble() {
      return checkRolePermission(ROUTE_EMBARGO.DETAIL);
    }
  }
})
export default class RouteManagement extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  // Search
  onSearch(value: string) {
    EmbargoController.setSearch(value);
    this.getListData(1, value, this.status);
  }
  public clearSearch() {
    EmbargoController.setSearch("");
    this.onSearch("");
  }
  get search() {
    return EmbargoController.search;
  }

  // Filter
  get status() {
    return EmbargoController.status;
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Upcoming", value: "pending" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "expired" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    this.statusName = name;
    EmbargoController.setStatus(value);
    this.getListData(1, this.search, value);
  }

  // Add New Embargo
  toPageAdd() {
    router.push("/network/embargo/add");
  }

  // Loading
  get isLoading() {
    return EmbargoController.isLoading;
  }

  // Pagination
  get page() {
    return EmbargoController.page;
  }
  get limit() {
    return EmbargoController.limit;
  }
  nextPage() {
    EmbargoController.setNextPage();
  }

  prevPage() {
    EmbargoController.setPrevPage();
  }

  toPage = debounce(value => {
    EmbargoController.setPageAction(value);
  }, 1000);

  // Get Data
  created() {
    this.getListData(1, "", "");
    EmbargoController.setSearch("");
    EmbargoController.setStatus("");
    this.setFirstPage();
  }

  onReload() {
    EmbargoController._onGetList({
      page: 1,
      limit: 10,
      search: "",
      status: ""
    });
  }
  getListData(page: number, search: string, status: string) {
    EmbargoController._onGetList({ page, limit: 10, search, status });
  }
  setFirstPage() {
    EmbargoController.setFirstPage();
  }
  get listData() {
    return EmbargoController.embargoData;
  }

  // Data Table
  columns = [
    {
      name: "ID Embargo",
      key: "id",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Embargo",
      key: "legName",
      styleHead: "w-1/4 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.embargo_name}</div>`;
      }
    },
    {
      name: "Kota Asal",
      key: "legName",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => this.onParseColumn(item.embargo_origin_id)
    },
    {
      name: "Destinasi",
      key: "transportModa",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => this.onParseColumn(item.embargo_destination_id)
    },
    {
      name: "Periode Embargo",
      key: "legName",
      styleHead: "w-7/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${dateToDateString(
          item.embargo_start_date_time
        )} ${dateToTime(
          item.embargo_start_date_time
        )} WIB - </br> ${dateToDateString(
          item.embargo_end_datetime
        )} ${dateToTime(item.embargo_end_datetime)} WIB</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/20 text-left",
      styleBody: "",
      render: (item: any) => {
        let styles = "";
        switch (item.status.toLowerCase()) {
          case "active":
            styles = "bg-green-lp-300 text-green-lp-200";
            break;
          case "expired":
            styles = "bg-red-300 text-red-lp-200";
            break;
          case "pending":
            styles = "bg-yellow-lp-200 text-yellow-lp-100";
            break;
        }

        let title = "";
        switch (item.status.toLowerCase()) {
          case "active":
            title = "Active";
            break;
          case "expired":
            title = "Inactive";
            break;
          case "pending":
            title = "Upcoming";
            break;
        }

        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${styles}"
      ><span class="capitalize font-medium">${title}</span></div></div>`;
      }
    }
  ];

  onParseColumn(value: string) {
    const spreadText = (e: any, i: number) =>
      `<div class='text-black-lp-300 mr-2 flex flex-row'><div class='rounded px-2 py-0 bg-gray-lp-200'>${
        i > 1 ? "..." : e
      }</div></div>`;

    if (value === "all") {
      return `<div class='text-black-lp-300 mr-2 flex flex-row'><div class='rounded px-2 py-0 bg-gray-lp-200'>${value}</div></div>`;
    }
    return `<div class='flex flex-row'>
          ${value
            .split(",")
            .splice(0, 3)
            .map(spreadText)
            .join(" ")}</div>`;
  }

  // To Detail
  onClickRow(item: any) {
    router.push(`/network/embargo/${item.id}`);
  }

  // Handle Error
  get isError() {
    return EmbargoController.isError;
  }
  get errorCause() {
    return EmbargoController.errorCause;
  }
}
