
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";

import { EmbargoController } from "@/app/ui/controllers/EmbargoController";
import { EmbargoDetail } from "@/domain/entities/Embargo";
import { ROUTE_EMBARGO } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    isEditAble() {
      return checkRolePermission(ROUTE_EMBARGO.EDIT);
    }
  }
})
export default class Detail extends Vue {
  goBack() {
    this.$router.push(`/network/embargo`);
  }

  goEdit(id: number) {
    this.$router.push(`/network/embargo/${id}/edit`);
  }

  onStop() {
    EmbargoController._onStop(this.id);
  }

  // Loading
  get isLoading() {
    return EmbargoController.isLoading;
  }

  // Get Detail Data
  created() {
    this.getDetail();
  }

  get id() {
    return Number(this.$route.params.id);
  }

  getDetail() {
    EmbargoController._onGetDetail(this.id);
  }

  get detailData(): EmbargoDetail {
    return EmbargoController.embargoDetailData;
  }

  // Format Date
  formatDate(date: string) {
    return formatDate(date);
  }

  // Handle Error
  get isError() {
    return EmbargoController.isError;
  }
  get errorCause() {
    return EmbargoController.errorCause;
  }
}
