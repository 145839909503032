import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import {
  ProductConfigurationRule,
  ProductConfigurationRuleList,
  ProductData,
  ProductEntities,
  ProductSender
} from "@/domain/entities/Product";
import { AxiosResponse } from "axios";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";

export class ProductDataMapper {
  public convertProductDataFromApi(res: AxiosResponse<any>): ProductEntities {
    const { data } = res;

    const vendors: ProductData[] = [];
    if (data.data.length === 0) {
      return new ProductEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        vendors.push(
          new ProductData({
            id: item.product_type_id,
            name: item.product_type_code,
            eCargoProduct: item.product_type_cargo_product_type_code,
            description: item.product_type_description,
            status: item.product_type_status,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updateAt: item.updated_at
          })
        );
      });
    }
    return new ProductEntities(
      new Pagination(data.meta.page, data.meta.limit),
      vendors
    );
  }

  public convertDetailProductDataFromApi(
    result: AxiosResponse<any>
  ): ProductData {
    const { data } = result;

    return new ProductData({
      id: data.data.product_type_id,
      name: data.data.product_type_code,
      eCargoProduct: data.data.product_cargo_product_type_code,
      description: data.data.product_type_description,
      status: data.data.product_type_status,
      createdBy: data.data.created_by,
      updatedBy: data.data.updated_by,
      createdAt: data.data.created_at,
      updateAt: data.data.updated_at
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertDataProductConfigurtionRule(
    result: AxiosResponse
  ): ProductConfigurationRuleList {
    const {
      data: { data }
    } = result;
    return new ProductConfigurationRuleList({
      data: data.map((item: any) => {
        return new ProductConfigurationRule({
          metaRule: JSON.parse(item.cr_excluded_booked_for),
          product: item.cr_product_type,
          parameter: Number(
            item.cr_rules.slice(item.cr_rules.lastIndexOf("-") + 1)
          ),
          isCampaign: item.cr_flag === "campaign"
        });
      })
    });
  }

  public convertProductSenderListDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new ProductSender({
                productTypeId: item.product_type_id,
                productTypeCode: item.product_type_code,
                productTypeDescription: item.product_type_description,
                productTypeStatus: item.product_type_status,
                productTypePriority: item.product_type_priority,
                productTypeCargoProductTypeCode:
                  item.product_type_cargo_product_type_code,
                createdBy: item.created_by,
                updatedBy: item.updated_by,
                createdAt: item.created_at,
                updatedAt: item.updated_at
              })
          )
        : []
    });
  }
}
