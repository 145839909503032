import { ProductApiRepository } from "@/app/infrastructures/repositories/api/ProductApiRepository";
import {
  ProductEntities,
  ProductData,
  ProductConfigurationRuleList
} from "@/domain/entities/Product";
import { injectable } from "tsyringe";
import {
  EditProductRequestInterface,
  AddProductRequestInterface
} from "@/data/payload/contracts/ProductRequest";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

@injectable()
export class ProductPresenter {
  private repository: ProductApiRepository;

  constructor(repo: ProductApiRepository) {
    this.repository = repo;
  }

  public getProductList(
    search: string,
    status: string,
    page: number,
    limit: number,
    productCode: string
  ): Promise<ProductEntities> {
    return this.repository.getProductList(
      search,
      status,
      page,
      limit,
      productCode
    );
  }

  public getDetailProduct(id: number): Promise<ProductData> {
    return this.repository.getDetailProduct(id);
  }

  public editProduct(
    id: number,
    payload: EditProductRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editProduct(id, payload);
  }

  public addProduct(
    payload: AddProductRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addProduct(payload);
  }

  public fetchProductApi(params: any): Promise<ProductEntities> {
    return this.repository.fetchProductApi(params);
  }

  public fetchProductConfigurationRule(
    params: ProductConfigurationRuleRequestParams
  ): Promise<ProductConfigurationRuleList> {
    return this.repository.fetchProductConfigurationRule(params);
  }

  public getProductSenderList(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getProductSenderList(params);
  }
}
