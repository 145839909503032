import { ProductDataMapper } from "@/data/persistences/mappers/ProductDataMapper";
import { ProductRepositoryInterface } from "@/data/persistences/repositories/contracts/ProductRepositoryInterface";
import {
  ProductEntities,
  ProductData,
  ProductConfigurationRuleList
} from "@/domain/entities/Product";
import ApiService from "../../services/ApiService";
import {
  EditProductRequestInterface,
  AddProductRequestInterface
} from "@/data/payload/contracts/ProductRequest";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import {
  EditProductApiRequest,
  AddProductApiRequest,
  ProductConfigurationRuleRequestParams
} from "@/data/payload/api/ProductApiRequest";
import { ProductEndpoint } from "@/app/infrastructures/endpoints/horde/ProductEndpoint";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class ProductApiRepository implements ProductRepositoryInterface {
  private service: ApiService;
  private mapper: ProductDataMapper;
  private endpoints: ProductEndpoint;

  constructor(
    service: ApiService,
    mapper: ProductDataMapper,
    endpoints: ProductEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getProductList(
    search: string,
    status: string,
    page: number,
    limit: number,
    productCode: string
  ): Promise<ProductEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListProduct(search, status, page, limit, productCode),
      {}
    );
    return this.mapper.convertProductDataFromApi(resp);
  }

  public async getDetailProduct(id: number): Promise<ProductData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailProduct(id),
      {}
    );
    return this.mapper.convertDetailProductDataFromApi(resp);
  }

  public async editProduct(
    id: number,
    payload: EditProductRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editProduct(id),
      undefined,
      payload as EditProductApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async addProduct(
    payload: AddProductRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.addProduct(),
      undefined,
      payload as AddProductApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async fetchProductApi(params: any): Promise<ProductEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.productApi(params),
      {}
    );
    return this.mapper.convertProductDataFromApi(resp);
  }

  public async fetchProductConfigurationRule(
    params: ProductConfigurationRuleRequestParams
  ): Promise<ProductConfigurationRuleList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.productConfigurationRuleEndpoint(params),
      {}
    );
    return this.mapper.convertDataProductConfigurtionRule(resp);
  }

  public async getProductSenderList(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getSenderList(params),
      {}
    );
    return this.mapper.convertProductSenderListDataFromApi(resp);
  }
}
