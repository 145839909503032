/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  CheckEmbargo,
  EmbargoData,
  EmbargoDetail,
  EmbargoEntities
} from "@/domain/entities/Embargo";
import { EmbargoPresenter } from "../presenters/EmbargoPresenter";
import {
  AddEmbargoApiRequest,
  CheckEmbargoApiRequest,
  EditEmbargoApiRequest
} from "@/data/payload/api/EmbargoApiRequest";
import { LocationPresenter } from "../presenters/LocationPresenter";
import { CityEntities } from "@/domain/entities/Location";
import { ProductEntities } from "@/domain/entities/Product";
import { CommodityList } from "@/domain/entities/Commodity";
import { CommodityPresenter } from "../presenters/CommodityPresenter";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { ProductPresenter } from "../presenters/ProductPresenter";
import { ProductComponent } from "@/app/infrastructures/dependencies/modules/ProductComponent";
import { ApiRequestList } from "@/domain/entities/MainApp";
ProductComponent.init();

export interface EmbargoState {
  embargoData: EmbargoData[];
  embargoDetailData: EmbargoDetail;
  isLoading: boolean;
  isError: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "embargo" })
class EmbargoStore extends VuexModule implements EmbargoState {
  public embargoData: Array<EmbargoData> = [];
  public embargoDetailData = new EmbargoDetail(
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    [],
    "",
    [],
    "",
    [],
    "",
    [],
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public isLoading = false;
  public isError = false;
  public page = 1;
  public limit = 10;
  public search = "";
  public status = "";
  public errorCause = "";
  public openModal = false;
  public openSuccess = false;
  public embargoName = "";
  public maxWeight = 1;
  public isErrorEdit = false;
  public isEmbargo = false;

  @Mutation
  public setEmbargoData(data: EmbargoData[]) {
    this.embargoData = data;
  }

  @Mutation
  public setEmbargoDetailData(data: EmbargoDetail) {
    this.embargoDetailData = data;
  }

  @Mutation
  public setDefaultEmbargoDetailData() {
    this.embargoDetailData = new EmbargoDetail(
      "",
      "",
      0,
      "",
      "",
      "",
      "",
      [],
      "",
      [],
      "",
      [],
      "",
      [],
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public setEmbargoName(value: string) {
    this.embargoName = value;
  }

  @Mutation
  public setMaxWeight(value: number) {
    this.maxWeight = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public async setFirstPage() {
    this.page = 1;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Action
  public _onGetList(params: {
    page: number;
    limit: number;
    search: string;
    status: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(EmbargoPresenter);
    presenter
      .getListEmbargo(params.page, params.limit, params.search, params.status)
      .then((res: EmbargoEntities) => {
        this.setEmbargoData(res.data);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    this.setError(false);
    const presenter = container.resolve(EmbargoPresenter);
    presenter
      .getDetailEmbargo(id)
      .then((res: EmbargoDetail) => {
        this.setEmbargoDetailData(res);
        this.setPeriodeStart(new Date(res.start_date_time));
        this.setPeriodeEnd(new Date(res.end_date_time));
        this.setEmbargoName(res.embargo_name);
        this.setMaxWeight(res.max_weight);
        this.setError(false);
        this.setErrorCause("");

        if (res.service_type) {
          const serviceType = res.service_type.split(",");
          if (serviceType.length > 0) {
            const data = serviceType.map((e: any) => ({
              name: e.toUpperCase(),
              value: e.toUpperCase()
            }));
            this.setTypeExclude(data);
          } else
            this.setTypeExclude([
              {
                name: res.service_type.toUpperCase(),
                value: res.service_type.toUpperCase()
              }
            ]);
        }
        return [];
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onAdd(params: {
    embargoName: string;
    originId: string;
    destinationId: string;
    serviceType: string;
    productType: string;
    commodityType: string;
    maxWeight: number;
    startDateTime: string;
    endDateTime: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(EmbargoPresenter);
    presenter
      .addEmbargo(
        new AddEmbargoApiRequest(
          params.embargoName,
          params.originId,
          params.destinationId,
          params.serviceType,
          params.productType,
          params.commodityType,
          params.maxWeight,
          params.startDateTime,
          params.endDateTime
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setOpenModal(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Embargo Gagal!", () =>
            this._onAdd(params)
          )
        );
      })
      .finally(() => {
        this.setLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onEdit(params: {
    embargoId: number;
    embargoName: string;
    originId: string;
    destinationId: string;
    serviceType: string;
    productType: string;
    commodityType: string;
    maxWeight: number;
    startDateTime: string;
    endDateTime: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(EmbargoPresenter);
    presenter
      .editEmbargo(
        new EditEmbargoApiRequest(
          params.embargoId,
          params.embargoName,
          params.originId,
          params.destinationId,
          params.serviceType,
          params.productType,
          params.commodityType,
          params.maxWeight,
          params.startDateTime,
          params.endDateTime
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setOpenModal(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Embargo Gagal!", () =>
            this._onEdit(params)
          )
        );
      })
      .finally(() => {
        this.setLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onStop(id: number) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(EmbargoPresenter);
    presenter
      .stopEmbargo(id)
      .then(() => this._onGetDetail(id))
      .catch((error: any) =>
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Hentikan Embargo Gagal!", () =>
            this._onStop(id)
          )
        )
      )
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this._onGetList({
      page: this.page,
      limit: 10,
      search: this.search,
      status: this.status
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this._onGetList({
        page: this.page,
        limit: 10,
        search: this.search,
        status: this.status
      });
    }
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this._onGetList({
      page: value,
      limit: 10,
      search: this.search,
      status: this.status
    });
  }

  // Date Picker
  public convertPeriodeStart = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes() + 10
  );
  public convertPeriodeEnd = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1,
    new Date().getHours(),
    new Date().getMinutes() + 10
  );
  public periodeStartTime = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes() + 10
  );
  public periodeEndTime = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1,
    new Date().getHours(),
    new Date().getMinutes() + 10
  );

  @Mutation
  public setPeriodeStart(date: Date) {
    this.convertPeriodeStart = new Date(date);
  }
  @Mutation
  public setPeriodeEnd(date: Date) {
    this.convertPeriodeEnd = new Date(date);
  }

  @Mutation
  public setPeriodeStartTime(date: Date) {
    this.periodeStartTime = new Date(date);
  }
  @Mutation
  public setPeriodeEndTime(date: Date) {
    this.periodeEndTime = new Date(date);
  }

  @Mutation
  public setResetPeriodeStart() {
    this.convertPeriodeStart = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes() + 10
    );
  }
  @Mutation
  public setResetPeriodeEnd() {
    this.convertPeriodeEnd = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      new Date().getHours(),
      new Date().getMinutes() + 10
    );
  }

  // Origin State
  public originCityOptionsData: Array<any> = [];
  public isLoadingOriginCity = false;

  // Handle Origin City on Embargo
  @Mutation
  public setLoadingOriginCity(value: boolean) {
    this.isLoadingOriginCity = value;
  }

  @Action
  public getCityOriginList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingOriginCity(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then((res: CityEntities) => {
        this.setOptionsOriginCityData(res);
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingOriginCity(false);
      });
  }

  @Mutation
  public setOptionsOriginCityData(data: CityEntities) {
    this.originCityOptionsData = data.cityData;
  }

  // Destination State
  public destinationCityOptionsData: Array<any> = [];
  public isLoadingDestinationCity = false;

  // Handle Destination City on Embargo
  @Mutation
  public setLoadingDestinationCity(value: boolean) {
    this.isLoadingDestinationCity = value;
  }

  @Action
  public getCityDestinationList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingDestinationCity(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then((res: CityEntities) => {
        this.setOptionsDestinationCityData(res);
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingDestinationCity(false);
      });
  }

  @Mutation
  public setOptionsDestinationCityData(data: CityEntities) {
    this.destinationCityOptionsData = data.cityData;
  }

  // Product State
  public productOptionsData: Array<any> = [];
  public isLoadingProduct = false;

  // Handle Product on Embargo
  @Mutation
  public setLoadingProduct(value: boolean) {
    this.isLoadingProduct = value;
  }

  @Action
  public getProductList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    productCode: string;
  }) {
    this.setLoadingProduct(true);
    const presenter = container.resolve(ProductPresenter);
    presenter
      .getProductList(
        params.search,
        params.status,
        params.page,
        params.limit,
        params.productCode
      )
      .then((res: ProductEntities) => {
        this.setOptionsProductData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingProduct(false);
      });
  }

  @Mutation
  public setOptionsProductData(data: ProductEntities) {
    this.productOptionsData = data.data;
  }

  // Commodity State
  public commodityOptionsData: Array<any> = [];
  public isLoadingCommodity = false;

  // Handle Commodity City on Embargo
  @Mutation
  public setLoadingCommodity(value: boolean) {
    this.isLoadingCommodity = value;
  }

  @Action
  public async getCommodityList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingCommodity(true);
    const presenter = await container.resolve(CommodityPresenter);
    presenter
      .getCommodityList(
        new RequestListCommodity({
          search: params.search,
          commodityStatus: params.status,
          page: params.page,
          limit: params.limit
        })
      )
      .then((res: CommodityList) => {
        this.setOptionsCommodityData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingCommodity(false);
      });
  }

  @Mutation
  public setOptionsCommodityData(data: CommodityList) {
    this.commodityOptionsData = data.data;
  }

  // Type
  typeExclude: Array<any> = [];
  @Mutation
  setTypeExclude(data: Array<any>) {
    this.typeExclude = data;
  }

  @Mutation
  public setIsEmbargo(data: CheckEmbargo): void {
    this.isEmbargo = data.isEmbargo;
  }

  @Action
  public checkIsEmbargo(
    payload: CheckEmbargoApiRequest
  ): Promise<CheckEmbargo> {
    this.setLoading(true);

    const presenter: EmbargoPresenter = container.resolve(EmbargoPresenter);
    return new Promise((resolve, reject) => {
      presenter
        .checkEmbargo(payload)
        .then(res => {
          this.setIsEmbargo(res);
          resolve(res)
        })
        .catch(err => {
          this.setIsEmbargo(new CheckEmbargo(false));
          reject(err);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });
  }
}

export const EmbargoController = getModule(EmbargoStore);
