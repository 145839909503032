
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import {
  convertArrayToString,
  dateToTime,
  ellipsisString,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { EmbargoController } from "@/app/ui/controllers/EmbargoController";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { ComodityTypeDetail, EmbargoDetail } from "@/domain/entities/Embargo";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";

@Options({
  components: {
    Input,
    Toggle,
    InputAdornment
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.push(`/network/embargo/${this.id}`);
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // Loading
  get isLoading() {
    return EmbargoController.isLoading;
  }

  // Embargo Name
  get embargoName() {
    return EmbargoController.embargoName;
  }
  setEmbargoName(value: string) {
    EmbargoController.setEmbargoName(value);
  }

  // Modal Success
  get openSuccess() {
    return EmbargoController.openSuccess;
  }
  onCloseSuccess() {
    EmbargoController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return EmbargoController.setOpenModal(true);
  }
  get openSave() {
    return EmbargoController.openModal;
  }
  onCloseSave() {
    EmbargoController.setOpenModal(false);
  }

  _onEdit() {
    EmbargoController._onEdit({
      embargoId: this.id,
      embargoName: this.embargoName,
      originId: this.convertToString(this.originCityExclude, "code"),
      destinationId: this.convertToString(this.destinationCityExclude, "code"),
      serviceType: this.convertToString(this.typeExclude, "value"),
      productType: this.convertToString(
        this.productExclude,
        "product_type_code"
      ),
      commodityType: this.convertToString(
        this.commodityExclude,
        "commodity_id"
      ),
      maxWeight: Number(this.detailData.max_weight),
      startDateTime: this.convertDateTime(this.periodeStart, this.periodeStartTime),
      endDateTime: this.convertDateTime(this.periodeEnd, this.periodeEndTime)
    });
  }

  // disable
  get isDisabled() {
    return (
      !this.embargoName ||
      !this.originCityExclude.length ||
      !this.destinationCityExclude.length ||
      !this.productExclude.length ||
      !this.commodityExclude.length ||
      !this.detailData.max_weight ||
      this.invalidStartDate ||
      this.invalidEndDate
    );
  }

  // Detail Embargo
  get detailData(): EmbargoDetail {
    return EmbargoController.embargoDetailData;
  }

  mounted() {
    this.getData();
  }

  getData() {
    EmbargoController._onGetDetail(this.id);
    this.fetchOriginCity("");
    this.fetchDestinationCity("");
    this.fetchProduct("");
    this.fetchCommodity("");
  }

  // Origin City
  fetchOriginCity(value: string) {
    EmbargoController.getCityOriginList({
      search: value,
      status: "",
      page: 1,
      limit: 10
    });
  }
  get originCityListData() {
    const tmp: Array<any> = [{ name: "ALL", code: "ALL" }];
    const origin = EmbargoController.originCityOptionsData;
    const isExist = this.destinationCityExclude.find(
      e => e.name === tmp[0].name
    );

    if (!isExist) {
      return [...tmp, ...origin];
    }
    return origin;
  }
  get originCityExclude() {
    return this.detailData.origin_detail;
  }
  get isLoadingOriginCity() {
    return EmbargoController.isLoadingOriginCity;
  }

  // Destination City
  fetchDestinationCity(value: string) {
    EmbargoController.getCityDestinationList({
      search: value,
      status: "",
      page: 1,
      limit: 10
    });
  }
  get destinationCityListData() {
    const tmp: Array<any> = [{ name: "ALL", code: "ALL" }];
    const destination = EmbargoController.destinationCityOptionsData;
    const isExist = this.originCityExclude.find(e => e.name === tmp[0].name);

    if (!isExist) {
      return [...tmp, ...destination];
    }
    return destination;
  }
  get destinationCityExclude() {
    return this.detailData.destination_detail;
  }
  get isLoadingDestinationCity() {
    return EmbargoController.isLoadingDestinationCity;
  }

  // Type
  typeListData: Array<any> = [
    { name: "PACKAGE", value: "PACKAGE" },
    { name: "DOCUMENT", value: "DOCUMENT" }
  ];
  get typeExclude() {
    return EmbargoController.typeExclude;
  }
  get isLoadingType() {
    return false;
  }

  // Product
  fetchProduct(value: string) {
    EmbargoController.getProductList({
      search: value,
      status: "",
      page: 1,
      limit: 10,
      productCode: ""
    });
  }
  get productListData() {
    return EmbargoController.productOptionsData.map((e: any) => ({
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_type_code: e.name
    }));
  }
  get productExclude() {
    return this.detailData.product_type_detail;
  }
  get isLoadingProduct() {
    return EmbargoController.isLoadingProduct;
  }

  // Commodity
  fetchCommodity(value: string) {
    EmbargoController.getCommodityList({
      search: value,
      status: "",
      page: 1,
      limit: 10
    });
  }
  get commodityListData() {
    const tmp: Array<ComodityTypeDetail> = [
      new ComodityTypeDetail("ALL", "ALL", "ALL")
    ];
    return [...tmp, ...EmbargoController.commodityOptionsData];
  }
  get commodityExclude() {
    return this.detailData.commodity_type_detail;
  }
  get isLoadingCommodity() {
    return EmbargoController.isLoadingCommodity;
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;

  get minDatePeriodeStart() {
    const date = new Date();
    return new Date(date.setDate(date.getDate()));
  }
  get minDatePeriodeEnd() {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    );
  }
  get periodeStart(): any {
    return EmbargoController.convertPeriodeStart;
  }
  get periodeEnd(): any {
    return EmbargoController.convertPeriodeEnd;
  }
  get periodeStartTime(): any {
    return EmbargoController.periodeStartTime;
  }
  get periodeEndTime(): any {
    return EmbargoController.periodeEndTime;
  }
  inputDateStart(param: Date) {
    EmbargoController.setPeriodeStart(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      this.invalidStartDate = !(
        param.getHours() >= new Date().getHours() &&
        param.getMinutes() >= new Date().getMinutes()
      );
    } else if (
      formatDateWithoutTime(new Date(param)) ===
      formatDateWithoutTime(new Date(this.periodeEnd))
    ) {
      this.invalidStartDate = true;
    } else if (new Date(this.periodeStart) > new Date(this.periodeEnd)) {
      this.invalidEndDate = true;
    } else this.invalidStartDate = false;
  }

  inputDateEnd(param: Date) {
    EmbargoController.setPeriodeEnd(param);
    this.invalidEndDate =
      formatDateWithoutTime(new Date(this.periodeStart)) ===
        formatDateWithoutTime(new Date(param)) ||
      new Date(this.periodeStart) > new Date(this.periodeEnd);
  }
  inputDateEndTime(param: Date) {
    EmbargoController.setPeriodeEndTime(param);
  }

  // Convert data
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  convertDate(date: Date, time: string) {
    return new Date(
      `${date.getMonth() +
        1}-${date.getDate()}-${date.getFullYear()} ${time} UTC`
    ).toISOString();
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))} ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  convertDateTime(date: Date, time: Date) {
    const resultDate = new Date(date).toLocaleDateString("fr-CA");

    const timeString = time?.toLocaleTimeString([], {
      hour12: false
    });
    return `${resultDate}T${timeString}.000+07:00`;
  }
}
